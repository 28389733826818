import { callApi, callApiFileStorage } from './service'

export const categoryServiceService = {
    getCategoryCatalog,
    getCategoryCatalogFilterByCompany,
    getSubCategoriesFilterByCategory,
    getSubCategoriesCatalogByCatId,
};
async function getCategoryCatalog()
{
    return callApi(`/BackEnd/GetCategoriesCatalog`, 'GET').then((result) => {
        if (result.responseCode == '00') {
            return result.data;
        } else {
            //debugger
            alert(result.responseMessage);
            return null;
        }

    });    
}
async function getCategoryCatalogFilterByCompany(companyId) {
    return callApi(`/BackEnd/GetCategoryFilters?companyId=${companyId}`, 'GET').then((result) => {
        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}
async function getSubCategoriesCatalogByCatId(categoryId) {
    // debugger
    // if (categoryId == null || categoryId == undefined) {
    //     return null;
    // }
    return callApi(`/BackEnd/GetSubCategoryCatalogByCategoryId?categoryId=${categoryId}`, 'GET').then((result) => {
        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getSubCategoriesFilterByCategory(categoryId, companyId) {
    return callApi(`/BackEnd/GetSubCategoryByCatId?categoryId=${categoryId}&companyId=${companyId}`, 'GET').then((result) => {
        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }
    });
}
