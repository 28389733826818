import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useInputSingleForm from '../../components/CustonHooks/inputFormHook'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { GeneralUpload } from '../../components/Helpers/generalUpload'
import { messageService } from '../../services/message.service'
import { EditorMessage } from './editorMessage'


export default function AnswerMessage(props) {
  const [localfiles, setLocalFiles] = useState([])
  const history = useHistory()

  const onSave = () => {
    inputs.originMessageId = props.originMessageId
    inputs.userProfileFrom = props.messageUserFrom
    inputs.subject = props.subject
    let usersTo = [props.messageUserTo]
    let data = { message: inputs, usersTo: usersTo }
    createMessage(data)
  }

  const createMessage = (data) => messageService.createMessage(data, localfiles)
    .then(() => history.push('/messages'))

  const { inputs, handleInputChange, handleSubmit } = useInputSingleForm(onSave, { messageText: '' })

  return (
    <fieldset className="mt-4">
      <legend>
          <span><i className="fas fa-share"></i></span> Responder
      </legend>
      <form onSubmit={handleSubmit} id="message_form">
        <EditorMessage content={inputs.messageText} name="messageText" handleInputChange={handleInputChange} /> <br />
        <GeneralUpload TitleLabel={"Adjuntos:"} IsMultipe={true} handleOnChangeFiles={setLocalFiles} />
      </form>
      <div className="text-right">
        <button className="btn secundary minimum mr-2" onClick={() => props.setShow(false)}>Cancelar</button>
        <button className="btn primary minimum" onClick={onSave}>Enviar</button>
      </div>
    </fieldset>

  )
}