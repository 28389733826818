import React, { Fragment, useEffect, useState } from "react";
import useInputSingleForm from "../components/CustonHooks/inputFormHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { callApi } from './service'

export const googleAds = {
    cartEvent,
    simpleEvent
}

const googleId = process.env.REACT_APP_GOOGLE_ADDS
const suscription = process.env.REACT_APP_GOOGLE_SUSCIPTION
const cart = process.env.REACT_APP_GOOGLE_CART

{/* <script>
  gtag('event', 'conversion', {'send_to': 'AW-11019800499/dYccCK26xIYYELOf04Yp'});
</script> */}


{/* <script>
  gtag('event', 'conversion', {
      'send_to': 'AW-11019800499/qEG9CMOnz4YYELOf04Yp',
      'value': 1.0,
      'currency': 'MXN'
  });
</script> */}


async function simpleEvent(type) {
    console.log('suscription',googleId+'/'+suscription)
    window.gtag('event', type, {'send_to': googleId+'/'+suscription});
}

async function cartEvent(type, data) {
    console.log('cart',googleId+'/'+cart)
    console.log('data',data)
    var event  = window.gtag('event', type, {
        'send_to': googleId+'/'+cart,
        'value': data,
        'currency': 'MXN'
    });
    console.log(event)
}

